<template>
    <answer-panel>
        <template slot="top">
            <div>
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item :to="{ path: '/chapter' }">章节列表</el-breadcrumb-item>
                            <el-breadcrumb-item>章节练习</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="title">{{$route.query.name}}</div>
        </template>
    </answer-panel>
  
</template>

<script>
  
    import AnswerPanel from "../components/AnswerPanel.vue"
    export default {
     
        components: {
            AnswerPanel
        }

    }
</script>
 